<template>

  <div class="popup--preview">
    <div class="popup--preview__box">
      <div class="popup--preview__box__header" v-resize @resize="resizeHeader">
        <Filters :on-change="onChangeFilters" @filterError="onErrorFilters" />
        <div class="popup--preview__box__close">
          <IconClosePopup :click="close" />
        </div>
        <va-progress-bar v-if="loading"
          indeterminate
          :style="{width: '100%', position: 'absolute', bottom: 0, left: 0, backgroundColor: '#ffffff'}"></va-progress-bar>
      </div>
      <div class="popup--preview__box__rich analytic" :style="{'height': viewHeight + 'px'}">

        <div class="iframe">
          <div class="row row-equal">
            <PerTime :data="timeStats" />
            <PieTime :data="pieStats" />
            <RetailerStats :data="retailerStats" :size="'xl3'" />
          </div>

          <Stats :impressionsCount="impressionsCount"
            :productsCount="false"
            :usersCount="usersCount" />

          <div class="row">
            <Browsers :data="browserStats" />
            <OS :data="osStats" />
            <Platforms :data="platformsStats" />
            <Devices :data="devicesStats" />
          </div>

          <Maps :ipData="IPStats"
            :countriesData="mapStats" />

        </div>
      </div>
    </div>
  </div>

</template>

<script>
import HelperUser from "../../services/Helpers/HelperUser";
import ElasticAPI from "../../services/API/ElasticAPI";
import BubbleMapData from "../../services/data/maps/BubbleMapData";
import fetchFilters from "../../mixins/fetchFilters";

export default {
  name: 'ModalEpackStat',
  mixins: [fetchFilters],

  components: {
    Filters: ()=> import('../../components/filters/Filters'),
    IconClosePopup: ()=> import('../ui/icons/IconClosePopup'),
    Platforms: ()=> import('../stats/Platforms'),
    Devices: ()=> import('../stats/Devices'),
    Maps: ()=> import('../stats/Maps'),
    RetailerStats: ()=> import('../stats/RetailerStats'),
    OS: ()=> import('../stats/OS'),
    Browsers: ()=> import('../stats/Browsers'),
    PieTime: ()=> import('../stats/PieTime'),
    PerTime: ()=> import('../stats/PerTime'),
    Stats: ()=> import('../stats/Stats'),
  },
  props: {
    epackageID: {
      type: String,
      default: () => "",
    },
  },

  data() {
    return {
      impressionsCount: 0,
      usersCount: 0,
      viewHeight: 0,
      isOpen: false,
      mapStats: {
        latlong: BubbleMapData.latlong,
        data: [],
      },
      IPStats: {
        latlong: {},
        data: [],
      },
      retailerStats: {
        labels: ['all'],
        datasets: [],
      },
      timeStats: {
        labels: ['all'],
        datasets: [],
      },
      pieStats: {
        labels: [],
        datasets: [],
      },
      browserStats: {
        labels: [],
        datasets: [],
      },
      osStats: {
        labels: [],
        datasets: [],
      },
      devicesStats: {
        labels: [],
        datasets: [],
      },
      platformsStats: {
        labels: [],
        datasets: [],
      },
    };
  },

  created() {

    this.defaultFilters = {
      range: this.filters.range,
      contentType: this.filters.contentType,
    };

    const { retailers, licenses, isAdmin } = HelperUser.getUserLicensesRetailers();

    this.filters.retailers = !isAdmin ? retailers.ids : [];
    this.filters.licenses = !isAdmin ? licenses.ids : [];

    this.defaultFilters["licenses"] = licenses.ids;
    this.defaultFilters["retailers"] = retailers.ids;

    if (isAdmin) {
      this.loading = false;
      return true;
    }

    this.loadData(licenses, retailers);
  },

  methods: {
    resizeHeader(event) {
      let { clientHeight } = event.target;
      this.viewHeight = window.innerHeight - clientHeight - 20;
    },

    close() {
      this.loading = true;
      this.$emit("close", false);
    },

    onChangeFilters(data) {
      this.loading = true;
      this.filters = data;

      this.defaultFilters["range"] = this.filters.range;
      this.defaultFilters["contentType"] = this.filters.contentType;

      this.loadData();
    },

    loadData() {
      const promiseRetailers = ElasticAPI.getRetailersStats(this.filters, this.epackageID);
      const promiseMap = ElasticAPI.getMapStats(this.filters, BubbleMapData, this.epackageID);
      const promiseTimeStat = ElasticAPI.getTimeStats(this.filters, this.epackageID);
      const promisePieStat = ElasticAPI.getPieStats(this.filters, this.epackageID);
      const promiseDataDevices = ElasticAPI.getDevicesStats(this.filters, this.epackageID);
      const promiseIPMap = ElasticAPI.getIPMap(this.filters, this.epackageID);
      const promiseUsers = ElasticAPI.getUsersCount(this.filters, this.epackageID);

      promiseUsers.then(data => {
        this.usersCount = data;
      });

      promiseIPMap.then(({ latlong, datasets }) => {
        this.IPStats = {
          latlong,
          data: datasets,
        };
      });

      promiseRetailers.then(data => {
        this.impressionsCount = 0;
        data.forEach(info => {
          this.impressionsCount += info.data.reduce((sum, current) => sum + current);
        });

        this.retailerStats = {
          labels: ['all'],
          datasets: data,
        };
      });

      promiseMap.then(data => {
        this.mapStats = {
          latlong: BubbleMapData.latlong,
          data,
        };
      });

      promiseTimeStat.then(({ labels, datasets }) => {
        this.timeStats = { labels, datasets };
      });

      promisePieStat.then(({ labels, datasets }) => {
        this.pieStats = { labels, datasets };
      });

      promiseDataDevices.then(({ browser, os, device, platforms }) => {

        this.browserStats = {
          labels: browser.labels,
          datasets: browser.datasets,
        };

        this.osStats = {
          labels: os.labels,
          datasets: os.datasets,
        };

        this.devicesStats = {
          labels: device.labels,
          datasets: device.datasets,
        };

        this.platformsStats = {
          labels: platforms.labels,
          datasets: platforms.datasets,
        };

      });

      const promiseAll = Promise.allSettled([
        promiseRetailers,
        promiseMap,
        promiseTimeStat,
        promisePieStat,
        promiseDataDevices,
        promiseIPMap,
      ]);

      promiseAll.then(() => {
        this.loading = false;
      });
    },
  },
};
</script>
